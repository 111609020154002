import React, { useCallback, useEffect, useState } from "react";
import { ShipmentStatusContext } from "./ShipmentStatusContext";
import IProductInfo from "../portal/shipment-processing/interfaces/IProductInfo";
import IShipmentInfo from "../portal/shipment-processing/interfaces/IShipmentInfo";
import { useUserContext } from "./UserContext";
import * as queries from "../graphql/queries";
import * as subscriptions from "../graphql/subscriptions";
import * as graphQlTypes from "../graphql/GraphQlTypes";
import GraphQLAPI, { GRAPHQL_AUTH_MODE, GraphQLResult } from "@aws-amplify/api-graphql";
import { ConvertToShipmentInfo, ConvertToShipmentInfos } from "../helpers/EventHelper";
import { ZenObservable } from "zen-observable-ts";
import { GraphQLSubscription } from "@aws-amplify/api";
import { API, Auth, graphqlOperation } from "aws-amplify";

type Props = {
  children: React.ReactNode;
};

export const ShipmentStatusContextProvider = ({ children }: Props) => {
  const userContext = useUserContext()!;
  const [preparedShipments, setPreparedShipments] = useState<IShipmentInfo[]>([]);
  const [noPreparedShipments, setNoPreparedShipments] = useState<Boolean>(false);
  const [pendingProducts, setPendingProducts] = useState<IProductInfo[]>([]);
  const [packedProducts, setPackedProducts] = useState<IProductInfo[]>([]);
  const [webSocketConnected, setWebSocketConnected] = useState<Boolean>(false);
  const [shipmentEventNotification, setShipmentEventNotification] = useState<graphQlTypes.ShipmentStatusUpdate>();

  // const loadProcessedShipments = async (shipments: IShipmentInfo[]) => {
  //   try {
  //     setProcessedShipments(shipments);
  //   } catch (error) {
  //     alert(error);
  //   }
  // };

  const addPreparedShipment = useCallback(
    async (item: IShipmentInfo) => {
      try {
        //console.log(item);
        var preparedShipment = preparedShipments.find((p) => p.shipmentId === item.shipmentId);
        if (preparedShipment) {
          return;
        }

        var shipmentsSorted = [...preparedShipments, item].sort(function (a: IShipmentInfo, b: IShipmentInfo) {
          if (a.shipmentCreationDate < b.shipmentCreationDate) return 1;
          if (a.shipmentCreationDate > b.shipmentCreationDate) return -1;
          return 0;
        });
        setPreparedShipments(shipmentsSorted);
      } catch (error) {
        alert(error);
      }
    },
    [preparedShipments]
  );

  const removePreparedShipment = async (shipmentId: string) => {
    var items = preparedShipments.filter((item) => item.shipmentId !== shipmentId);
    // console.log(items);
    if (items.length === 0) {
      setNoPreparedShipments(true);
    }
    if (pendingProducts.length > 0) {
      if (pendingProducts[0].shipmentId === shipmentId) {
        setPendingProducts([]);
      }
    }
    if (packedProducts.length > 0) {
      if (packedProducts[0].shipmentId === shipmentId) {
        setPackedProducts([]);
      }
    }
    setPreparedShipments(items);
  };

  const loadData = async () => {
    const fetchData = async () => {
      try {
        if (userContext.userId) {
          const getPreparedShipmentsQueryVariables = {
            userId: userContext.userId,
          } as graphQlTypes.GetPreparedShipmentsQueryVariables;
          const result = await (GraphQLAPI.graphql({
            query: queries.getPreparedShipments,
            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
            variables: getPreparedShipmentsQueryVariables,
          }) as Promise<GraphQLResult<graphQlTypes.GetPreparedShipmentsQuery>>);

          // console.log(result.data);

          if (result.data && result.data.getPreparedShipments.items.length) {
            var shipments = ConvertToShipmentInfos(result.data?.getPreparedShipments.items);

            var shipmentsSorted = shipments.sort(function (a: IShipmentInfo, b: IShipmentInfo) {
              if (a.shipmentCreationDate < b.shipmentCreationDate) return 1;
              if (a.shipmentCreationDate > b.shipmentCreationDate) return -1;
              return 0;
            });

            setPreparedShipments(shipmentsSorted);
            setNoPreparedShipments(false);
          } else {
            setPreparedShipments([]);
            setNoPreparedShipments(true);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    await fetchData();
  };

  useEffect(() => {
    if (!webSocketConnected) {
      subscribeToUpdates();
    }
    loadData();
  }, [userContext.userId]);

  useEffect(() => {
    async function processEvent() {
      if (shipmentEventNotification) {
        // console.info(shipmentEventNotification);
        // console.info(shipmentEventNotification.shipmentStatus);
        // console.info(shipmentEventNotification.shipmentStatus === "ShipmentProcessed");
        if (shipmentEventNotification.shipmentStatus === "PROCESSED") {
          removePreparedShipment(shipmentEventNotification.shipmentId);
        } else if (shipmentEventNotification.shipmentStatus === "PREPARED") {
          const shipmentInfo = ConvertToShipmentInfo(shipmentEventNotification);
          if (shipmentInfo) {
            await addPreparedShipment(shipmentInfo);
          }
        } else {
          throw new Error("Shipment status is not supported" + shipmentEventNotification.shipmentStatus);
        }
      }
    }

    processEvent();
  }, [shipmentEventNotification]);

  const onShipmentStatusEvent = useCallback(
    (value) => {
      console.info(value);
      var event = value.data.onStatusUpdate as graphQlTypes.ShipmentStatusUpdate;
      if (event) {
        setShipmentEventNotification(event);
      }
    },
    [preparedShipments]
  );

  function timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay));
  }

  const onAppSyncSubscriptionError = (error: string) => {
    console.error(error);
    const errorDetails = JSON.parse(JSON.stringify(error));
    if (errorDetails.error.errorCode === 8) {
      console.error(errorDetails.error.errorMessage);
    }
    if (webSocketSubscription) {
      webSocketSubscription.unsubscribe();
    }
    establishConnection(5000);
  };

  async function establishConnection(delay: number) {
    await timeout(delay);
    subscribeToUpdates();
  }

  var webSocketSubscription: ZenObservable.Subscription;

  const subscribeToUpdates = async () => {
    try {
      if (userContext.userId) {
        console.info("Subscribing to AppSync for user " + userContext.userId);

        if (webSocketSubscription) {
          webSocketSubscription.unsubscribe();
        }

        const session = await Auth.currentSession();
        const idToken = await session.getIdToken();
        const token = await idToken.getJwtToken();

        webSocketSubscription = API.graphql<GraphQLSubscription<typeof subscriptions.onStatusUpdate>>(
          graphqlOperation(
            subscriptions.onStatusUpdate,
            {
              userId: userContext.userId,
            },
            token
          )
        ).subscribe({
          next: ({ provider, value }) => onShipmentStatusEvent(value),
          error: (error: string) => onAppSyncSubscriptionError(error),
        });

        //console.log(webSocketSubscription);
        setWebSocketConnected(true);
        console.info("Subscribed to AppSync for user " + userContext.userId);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  return (
    <ShipmentStatusContext.Provider
      value={{
        preparedShipments,
        setPreparedShipments,
        noPreparedShipments,
        pendingProducts,
        setPendingProducts,
        packedProducts,
        setPackedProducts,
      }}
    >
      {children}
    </ShipmentStatusContext.Provider>
  );
};
