import React, { useEffect, useState } from "react";
import { UserContext } from "./UserContext";
import { Auth, Hub } from "aws-amplify";

type Props = {
  children: React.ReactNode;
};

export const UserContextProvider = ({ children }: Props) => {
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");

  Hub.listen("auth", (data: any) => {
    switch (data.payload.event) {
      case "signIn":
        applyUserName();
        //console.info("user signed in");
        break;
    }
  });

  const applyUserName = async () => {
    Auth.currentAuthenticatedUser()
      .then((user: any) => {
        Auth.currentUserInfo().then((info: any) => {
          setUserId(info.attributes["sub"]);
          setUserName(info.attributes["given_name"] + " " + info.attributes["family_name"]);
        });
      })
      .catch((err: any) => {
        //console.error(err);
      });
  };

  useEffect(() => {
    // console.info("Store Context Initialized");
    applyUserName();
  }, []);

  return (
    <UserContext.Provider
      value={{
        userId,
        userName,
        setUserName,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
