/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPicklist = /* GraphQL */ `
  query GetPicklist($metadata: String!, $userId: ID!) {
    getPicklist(metadata: $metadata, userId: $userId) {
      metadata
      orderId
      picklistCreationDate
      picklistId
      picklistStatus
      productId
      quantity
      shipmentId
      userId
      warehouseId
    }
  }
`;
export const getPicklists = /* GraphQL */ `
  query GetPicklists(
    $limit: Int
    $metadata: String!
    $nextToken: String
    $userId: ID!
  ) {
    getPicklists(
      limit: $limit
      metadata: $metadata
      nextToken: $nextToken
      userId: $userId
    ) {
      items {
        metadata
        orderId
        picklistCreationDate
        picklistId
        picklistStatus
        productId
        quantity
        shipmentId
        userId
        warehouseId
      }
      nextToken
    }
  }
`;
export const getPicklistsData = /* GraphQL */ `
  query GetPicklistsData($metadata: String!, $userId: ID!) {
    getPicklistsData(metadata: $metadata, userId: $userId) {
      items {
        metadata
        orderId
        picklistCreationDate
        picklistId
        picklistStatus
        productId
        quantity
        shipmentId
        userId
        warehouseId
      }
      nextToken
    }
  }
`;
export const getPreparedShipments = /* GraphQL */ `
  query GetPreparedShipments($limit: Int, $nextToken: String, $userId: ID!) {
    getPreparedShipments(
      limit: $limit
      nextToken: $nextToken
      userId: $userId
    ) {
      items {
        metadata
        orderId
        shipmentCreationDate
        shipmentId
        shipmentStatus
        userId
      }
      nextToken
    }
  }
`;
export const getShipment = /* GraphQL */ `
  query GetShipment($metadata: String!, $userId: ID!) {
    getShipment(metadata: $metadata, userId: $userId) {
      metadata
      orderId
      shipmentCreationDate
      shipmentId
      shipmentStatus
      userId
    }
  }
`;
export const getWarehouse = /* GraphQL */ `
  query GetWarehouse($warehouseId: ID!) {
    getWarehouse(warehouseId: $warehouseId) {
      warehouseCode
      warehouseId
      warehouseName
    }
  }
`;
export const getWarehouses = /* GraphQL */ `
  query GetWarehouses($limit: Int, $nextToken: String) {
    getWarehouses(limit: $limit, nextToken: $nextToken) {
      items {
        warehouseCode
        warehouseId
        warehouseName
      }
      nextToken
    }
  }
`;
