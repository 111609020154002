import { AnimatePresence } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./PreparedShipmentsBucket.css";
import IPreparedShipmentsBucket from "../interfaces/IPreparedShipmentsBucket";
import ProcessedShipmentCard from "./PreparedShipmentCard";

export default function PreparedShipmentsBucket({ bucketTitle, bucketIcon, shipments, onShipmentSelected }: IPreparedShipmentsBucket): JSX.Element {
  return (
    <div className="prepared-shipments-bucket-segment">
      <div className="prepared-shipments-bucket-title-container">
        <FontAwesomeIcon className="prepared-shipments-bucket-title-icon" icon={bucketIcon} />
        <span className="prepared-shipments-bucket-title-label">{bucketTitle}</span>
      </div>
      <AnimatePresence>
        {shipments.map((shipment) => {
          return <ProcessedShipmentCard key={shipment.shipmentId} shipment={shipment} onShipmentSelected={onShipmentSelected} />;
        })}
      </AnimatePresence>
    </div>
  );
}
