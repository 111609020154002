import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useUserContext } from "../../contexts/UserContext";
import "./NavigationBar.css";

interface INavigationBar {
  portalName: string;
}

export default function NavigationBar({ portalName }: INavigationBar): JSX.Element {
  const userContext = useUserContext()!;

  return (
    <Navbar className="navigation-bar" collapseOnSelect bg="dark" variant="dark" sticky="top">
      <Navbar.Brand className="navbar-brand " as={Link} to="/">
        <div className="navbar-brand-container">
          <label className="navbar-brand-text-label link-item-hover">{portalName}</label>
        </div>
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse className="navigation-container">
        <Nav className="navigation-section ml-auto">
          <div className="nav-vertical-delimiter"></div>
          <Nav.Item className="nav-item-generic">
            <Nav.Link eventKey="1" className="nav-link-item link-item-hover" as={Link} to="/">
              <span className="nav-normal-text-label">Shipments Dashboard</span>
            </Nav.Link>
          </Nav.Item>
          <div className="nav-vertical-delimiter"></div>
          <Nav.Item className="nav-item-generic">
            <Nav.Link eventKey="2" className="nav-link-item link-item-hover" as={Link} to="/about">
              <span className="nav-normal-text-label">About</span>
            </Nav.Link>
          </Nav.Item>
          <div className="navigation-right-section">
            <Nav.Item className="nav-item-login">
              {userContext.userName ? (
                <div className="nav-item-login-container">
                  <span className="nav-register-label">Signed in as:</span>
                  <Link className="nav-register-link link-item-hover" to="/profile">
                    {userContext.userName}
                  </Link>
                </div>
              ) : (
                <span />
              )}
            </Nav.Item>
          </div>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
