/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onStatusUpdate = /* GraphQL */ `
  subscription OnStatusUpdate($userId: ID!) {
    onStatusUpdate(userId: $userId) {
      metadata
      orderId
      shipmentCreationDate
      shipmentId
      shipmentStatus
      userId
    }
  }
`;
