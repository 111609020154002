import React from "react";

type UserContextType = {
  userId: string;
  userName: string;
  setUserName: (value: string) => void;
};

export const UserContext = React.createContext<UserContextType | undefined>(undefined);

export const useUserContext = () => React.useContext(UserContext);
