import React from "react";
import IProductInfo from "../portal/shipment-processing/interfaces/IProductInfo";
import IShipmentInfo from "../portal/shipment-processing/interfaces/IShipmentInfo";

type ShipmentStatusContextType = {
  preparedShipments: IShipmentInfo[];
  setPreparedShipments: (shipments: IShipmentInfo[]) => void;
  noPreparedShipments: Boolean;
  pendingProducts: IProductInfo[];
  setPendingProducts: (products: IProductInfo[]) => void;
  packedProducts: IProductInfo[];
  setPackedProducts: (products: IProductInfo[]) => void;
};

export const ShipmentStatusContext = React.createContext<ShipmentStatusContextType | undefined>(undefined);

export const useShipmentStatusContext = () => React.useContext(ShipmentStatusContext);
