import { useShipmentStatusContext } from "../../contexts/ShipmentStatusContext";
import IShipmentProcessingManager from "./interfaces/IShipmentProcessingManager";
import GraphQLAPI, { GRAPHQL_AUTH_MODE, GraphQLResult } from "@aws-amplify/api-graphql";
import "./ShipmentProcessingManager.css";
import * as queries from "../../graphql/queries";
import * as graphQlTypes from "../../graphql/GraphQlTypes";
import { faCartFlatbed } from "@fortawesome/free-solid-svg-icons";
import PreparedShipmentsBucket from "./components/PreparedShipmentsBucket";
import Spinner from "react-bootstrap/Spinner";
import IShipmentInfo from "./interfaces/IShipmentInfo";
import ProductPackingControl from "./components/ProductPackingControl";
import { useUserContext } from "../../contexts/UserContext";
import { useRef, useState } from "react";
import { ConvertToProductInfos } from "../../helpers/EventHelper";

export default function ShipmentProcessingManager(props: IShipmentProcessingManager): JSX.Element {
  const shipmentStatusContext = useShipmentStatusContext()!;
  const userContext = useUserContext()!;
  const selectedShipment = useRef<IShipmentInfo>();
  const [productsLoading, setProductsLoading] = useState<Boolean>(false);

  const onShipmentSelected = async (shipment: IShipmentInfo) => {
    const fetchData = async () => {
      if (selectedShipment.current !== undefined) {
        if (selectedShipment.current.shipmentId === shipment.shipmentId) {
          return;
        }
        selectedShipment.current.isSelected = false;
      }
      shipment.isSelected = true;
      selectedShipment.current = shipment;
      try {
        setProductsLoading(true);
        const getPicklistsQueryVariables = {
          userId: userContext.userId,
          metadata: "picklist#" + shipment.shipmentId + "#",
        } as graphQlTypes.GetPicklistsQueryVariables;
        const result = await (GraphQLAPI.graphql({
          query: queries.getPicklists,
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
          variables: getPicklistsQueryVariables,
        }) as Promise<GraphQLResult<graphQlTypes.GetPicklistsQuery>>);

        if (result.data && result.data?.getPicklists.items.length) {
          shipmentStatusContext.setPackedProducts([]);
          var picklists = ConvertToProductInfos(result.data?.getPicklists.items);
          shipmentStatusContext.setPendingProducts(picklists);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setProductsLoading(false);
      }
    };

    await fetchData();
  };

  const onClearDataHandler = async () => {
    //selectedShipment.current = undefined;
    //await loadData();
    // if (shipmentStatusContext.preparedShipments.length > 0) {
    //   await onShipmentSelected(shipmentStatusContext.preparedShipments[0]);
    //   //shipmentStatusContext.preparedShipments[0].isSelected = true;
    // }
  };

  return (
    <div className="shipments-dashboard-main">
      {shipmentStatusContext.preparedShipments.length > 0 ? (
        <div className="shipments-dashboard-container">
          <section className="shipments-dashboard-section">
            <PreparedShipmentsBucket
              bucketTitle="Shipments To Process:"
              bucketIcon={faCartFlatbed}
              shipments={shipmentStatusContext.preparedShipments}
              onShipmentSelected={onShipmentSelected}
            />
            <ProductPackingControl selectedShipment={selectedShipment.current} productsLoading={productsLoading} onClearData={onClearDataHandler} />
          </section>
        </div>
      ) : shipmentStatusContext.noPreparedShipments ? (
        <div className="shipments-dashboard-container-empty">
          <section className="shipments-dashboard-empty-section">
            <span className="shipments-dashboard-empty-label">You do not have any Shipments to process.</span>
          </section>
        </div>
      ) : (
        <div className="shipments-dashboard-container-empty">
          <Spinner className="warehouse-spinner" animation="border" role="status" variant="warning">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
    </div>
  );
}
