import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.css";
import { ShipmentStatusContextProvider } from "./contexts/ShipmentStatusContextProvider";
import { UserContextProvider } from "./contexts/UserContextProvider";

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <UserContextProvider>
        <ShipmentStatusContextProvider>
          <App />
        </ShipmentStatusContextProvider>
      </UserContextProvider>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById("root")
);
