/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const completeShipment = /* GraphQL */ `
  mutation CompleteShipment(
    $orderId: String!
    $shipmentId: String!
    $userId: ID!
  ) {
    completeShipment(
      orderId: $orderId
      shipmentId: $shipmentId
      userId: $userId
    )
  }
`;
export const publishShipmentStatusUpdate = /* GraphQL */ `
  mutation PublishShipmentStatusUpdate(
    $statusUpdate: PublishShipmentStatusUpdateInput!
  ) {
    publishShipmentStatusUpdate(statusUpdate: $statusUpdate) {
      metadata
      orderId
      shipmentCreationDate
      shipmentId
      shipmentStatus
      userId
    }
  }
`;
