import { Picklist, Shipment } from "../graphql/GraphQlTypes";
import IProductInfo from "../portal/shipment-processing/interfaces/IProductInfo";
import IShipmentInfo from "../portal/shipment-processing/interfaces/IShipmentInfo";
import * as graphQlTypes from "../graphql/GraphQlTypes";

export const ConvertToProductInfos = (picklists: Picklist[]) => {
  var result: IProductInfo[] = [];

  picklists.forEach(function (picklist: any) {
    var picklistInfo: IProductInfo = {
      id: picklist.picklistId,
      metadata: picklist.metadata,
      orderId: picklist.orderId,
      picklistCreationDate: picklist.picklistCreationDate,
      picklistId: picklist.picklistId,
      picklistStatus: picklist.picklistStatus,
      productId: picklist.productId,
      quantity: picklist.quantity,
      shipmentId: picklist.shipmentId,
      userId: picklist.userId,
      warehouseId: picklist.warehouseId,
    };

    result.push(picklistInfo);
  });

  return result;
};

export const ConvertToShipmentInfos = (shipments: Shipment[]) => {
  var result: IShipmentInfo[] = [];
  shipments.forEach(function (shipment: any) {
    var shipmentInfo: IShipmentInfo = {
      metadata: shipment.metadata,
      orderId: shipment.orderId,
      shipmentId: shipment.shipmentId,
      userId: shipment.userId,
      shipmentStatus: shipment.shipmentStatus,
      shipmentCreationDate: shipment.shipmentCreationDate,
      isSelected: false,
    };
    result.push(shipmentInfo);
  });
  return result;
};

export const ConvertToShipmentInfo = (shipment: graphQlTypes.ShipmentStatusUpdate) => {
  var shipmentInfo: IShipmentInfo = {
    metadata: shipment.metadata,
    orderId: shipment.orderId,
    shipmentId: shipment.shipmentId,
    userId: shipment.userId,
    shipmentStatus: shipment.shipmentStatus,
    shipmentCreationDate: shipment.shipmentCreationDate,
    isSelected: false,
  };
  return shipmentInfo;
};
