import { Routes, Route } from "react-router-dom";
import NavigationBar from "./portal/navigation/NavigationBar";
import Amplify from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";

import "@aws-amplify/ui-react/styles.css";
import "./App.css";
import awsExports from "./aws-exports";
import ProfileManager from "./portal/profile/ProfileManager";
import ShipmentProcessingManager from "./portal/shipment-processing/ShipmentProcessingManager";
import AboutManager from "./portal/about/AboutManager";

declare const process: {
  env: {
    REACT_APP_PORTAL_API_URL: string;
    REACT_APP_PUB_SUB_REGION: string;
    REACT_APP_PUB_SUB_ENDPOINT: string;
    REACT_APP_PORTAL_TITLE: string;
  };
};

Amplify.configure(awsExports);

document.title = process.env.REACT_APP_PORTAL_TITLE;

const formFields = {
  signUp: {
    given_name: {
      placeholder: "First Name",
      order: 1,
    },
    family_name: {
      placeholder: "Last Name",
      order: 2,
    },
    email: {
      order: 3,
    },
    password: {
      order: 4,
    },
    confirm_password: {
      order: 5,
    },
  },
};

function App(): JSX.Element {
  return (
    <div className="admin-dashboard-container">
      <NavigationBar portalName={process.env.REACT_APP_PORTAL_TITLE} />
      <main id="main">
        <Authenticator className="data-amplify-authenticator" formFields={formFields}>
          {() => (
            <Routes>
              <Route path="/" element={<ShipmentProcessingManager />} />
              <Route path="about" element={<AboutManager />} />
              <Route path="profile" element={<ProfileManager />} />
            </Routes>
          )}
        </Authenticator>
      </main>
    </div>
  );
}

export default App;
